import React from 'react';
import styled from '@emotion/styled';

export const Check = ({ text, estado, subtext }) => {

  let icons = {
    pendiente : "info",
    rechazada : "error",
    fallida   : "error",
    aceptada  : "check",
    info      : "info"
  };

  let icon = icons[estado.toLowerCase()];

  const IconAnimado = styled.section`
    text-align:center;
    
    img{
      height:50px;
      width:50px;
    }
    
   .text{
    font-size:16px;
    color:#999999;
    width:90%;
    max-width:320px;
    margin:auto;
    display:block;
    font-weight:700;
    }

  .subtext{
    font-size:12px;
    font-weight:400;
    color:#747474;
    width:90%;
    max-width:320px;
    margin:auto;
    display:block;
    }
}`;

  return (
    <IconAnimado className={"pb-2"}>
      <img src={`https://multimedia-epayco.s3.amazonaws.com/dashboard/modal_icons/${icon}.png`} alt="" />
      <div className="wc pt-2 ">
        <span  className={"text mb-0"}>{text}</span>
        <span  className={"subtext mb-0"}>{subtext}</span>
      </div>
    </IconAnimado>
  );
};
