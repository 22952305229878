import styled from '@emotion/styled';
import Flex from '../../../../Flex';


export const ChargeToC = styled.div`
  position:relative;
  width:100%;
  text-align:center;
  color:#3a3a3a;
  font-size:10px;
  background:#f1f1f2;

  span{
    position:relative;
    z-index:2;
  }



  .charge{
    height:100%;
    width:${props =>props.width};
    position:absolute;
    top:0;
    left:0;
    background:#D3D3D3;
    transition: all ${props=> props.time}s linear;
  }
`;

export const MyToast = styled(Flex)`
  border-radius:3px;
  background:${props => props.bg};
  color:black;
  border:1px solid ${props => props.brd};
  font-size:12px;
  line-height:1.4em;


  img{
    width:100%;
    height:auto;
    display:block;
  }
`;

export const Container = styled(Flex)`

  transform:translateZ(0);

    .resumen{
      border: 3px solid rgba(241, 241, 241, 0.9);

     .head{
      background:rgba(241, 241, 241, 0.9);
      padding:.5em .75em;

      p{
        margin:0;
        font-size:15px;
        color:#999999;
      }

      small{font-size:12px;}

     }

     .celda{
        padding: .75em;
     }

     .name{font-size:12px; margin-bottom:5px;}
     .value{font-size:15px; font-weight:500; color:#999999;}
    }

    .description{
      font-size:15px;
      margin-top:15px;
      color:#999999;
      transform:translateZ(0);

     }
`;

export const TablaS = styled.div`
  width:100%;
  border:1px solid #e4e4e4;
  font-size:15px;
  color:#999999;
  text-align:left;

  .label{
    color:#58595A;
    font-size:12px;
    display:block;
    width:100%;
    line-height:14px;
    text-align:left;
    padding:0 0 5px;
  }

  .relleno{
    background-color: #e4e4e4;
    padding:.5em;

    .value{
      color:#777777;
    }
  }

  .keyValue{
    .value{
      display:block;
      line-height:1.3em;
      font-size:15px;
      font-weight:300;
    }
  }

`;

export const TablaC = styled.div`
  width:100%;
  border:1px solid #e4e4e4;
  font-size:15px;
  color:#999999;
  text-align:left;
  .icon {
    position: fixed;
    float: right;
    left: 90%;
    cursor: pointer;
  }
  .label{
    color:#58595A;
    font-size:12px;
    display:block;
    width:100%;
    line-height:14px;
    text-align:left;
    padding:0 0 5px;
  }
  .relleno{
    background-color: #e4e4e4;
    padding:.5em;
    .value{
      color:#777777;
    }
  }
  .keyValue{
    .value{
      display:block;
      line-height:1.3em;
      font-size:15px;
      font-weight:300;
    }
  }
`;