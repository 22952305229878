import React, { lazy, Suspense, useEffect } from 'react';
import Loading from '../../../loading';
import useGlobal from '../../../../config/global';
const SimpleContainer = lazy(()=> import('../../../UI/simpleContainer') );
const TitleSection = lazy(()=> import('../../../TitleSection/TitleSection'))
const FacuracionDebitoForm = lazy(()=> import('./forms/facturacion'))

const FacturacionDebito = (props) => {
  const [, globalActions] = useGlobal();

  useEffect(() => {
    globalActions.updateState({
      migasUp:{
        show:true,
        icon:true,
        iconName:'account_balance'
      }
    })
  }, []);

  return (
    <Suspense fallback={
      <Loading show={true} texto={"Cargando sección..."}/>
    }>
      <SimpleContainer>
        <TitleSection text={"Ingrese sus datos"}/>
        <FacuracionDebitoForm/>
      </SimpleContainer>
    </Suspense>
  );
};

export default React.memo(FacturacionDebito);
