import React, { useState } from 'react';
import { TablaC } from './styles';
import Collapse from "react-collapse";
import Flex from '../../../Flex';

function TableCollapse({ header = {}, content = [], footer = {} }) {

  const [detail,setDeail]  = useState(false);

  return (
    <TablaC>
      <div className="relleno wc">
        <span className={'label'}>{header.name}</span>
        <span className={'icon'} onClick={() => setDeail(!detail)}> <i className={'material-icons'}>{!detail ? 'expand_more' : 'expand_less'}</i></span>
        <span className={"value"}>{header.value || "--------" }</span>
      </div>
      <Collapse isOpened={detail} className={"wc"}>
        <Flex className={'wc'} alg={"flex-start"}>
            {content.map((item, index) => (
            <Flex
                jc={'flex-start'}
                key={index}
                flex={`1 0 ${item.ancho ? item.ancho : "49%"}`}
                className={'p-2 keyValue'}
            >
                <span className={'label'}>{item.name}</span>
                <span className={"value col-12 px-0"}>{item.value || "--------"}</span>
            </Flex>
            ))}
        </Flex>
      </Collapse>
      {footer.name && (
        <div className="relleno">
          <span className="label">{footer.name}</span>
          <span>${footer.value}</span>
        </div>
      )}
    </TablaC>
  );
}

export default React.memo(TableCollapse);