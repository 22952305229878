import React, { useEffect, useRef, useState, useMemo } from 'react';
import { useParams } from 'react-router';
import { useMutation} from '@apollo/react-hooks';
import { PinCode } from 'baseui/pin-code';
import is from 'is_js';
import { animated, useTransition } from 'react-spring';
import { SpanLink } from '../../../BtnT/BtnT';
import ChangePhone from './changePhone';
import { closeCheckout } from '../../../../pages/onepage';
import { getRefSubscribed } from '../../../../util/helpers/getRefSubscribed';

/**
 * Mutation
 */
import {
  SEND_SMS,
  SMS_VERIFICATION,
  // SUBSCRIPTION,
  FACTURATION,
} from '../../../../data/mutations/payment';
//TODO: create action update button admin subscription
// import { TOKEN_SUBSCRIPTION } from '../../../../data/mutations/subscriptions';

/**
 * Constast
 */
import { STEP } from '../../constants';
import { SEND_EMAIL_NOTIFICATION } from '../../../../data/mutations/email';
import { GET_CREDIT_CARD } from '../../../../data/mutations/credit-card';

/**
 * Global state
 */
import useGlobal from '../../../../config/global';

/**
 * Components
 */
import Flex from '../../../Flex';
import Loading from '../../../loading';
import axios from 'axios';
import { BigIcon } from '../../../UI';

const InserCode = ({ send, errorCode }) => {
  const [pin, setValues] = React.useState(['', '', '', '']);

  useMemo(() => {
    if (pin.every((ele) => ele !== '')) {
      send(pin);
    }
  }, [pin]);

  useMemo(() => {
    if (errorCode) {
      setValues(['', '', '', '']);
    }
  }, [errorCode]);

  return (
    <div>
      <PinCode
        autoFocus={!errorCode}
        error={errorCode}
        className={'text-center'}
        values={pin}
        onChange={({ values }) => setValues(values)}
        overrides={{
          Root: {
            style: ({ $theme }) => {
              return {
                justifyContent: 'center',
              };
            },
          },
          BaseInput: {
            style: ({ $theme }) => {
              return {
                outline: `${$theme.colors.warning600} solid`,
                backgroundColor: $theme.colors.warning600,
                height: '70px',
                border: '1px solid red',
              };
            },
          },
          Input: {
            style: ({ $theme }) => {
              return {
                outline: `${$theme.colors.warning600} solid`,
                backgroundColor: $theme.colors.warning600,
                height: '70px',
                border: '1px solid red',
              };
            },
          },
        }}
      />
    </div>
  );
};

async function sendNotification(sendEmailInfo, dataEmail, dataRequestRefSubscribed) {
  try {

    var refs = await getRefSubscribed(dataRequestRefSubscribed);
    dataEmail.refSubscribed = refs.refSubscribed;
    dataEmail.payReference = refs.refPayment;

    sendEmailInfo({
      variables: {
        template: '/info/nuevo/metodo/suscripcion',
        data: dataEmail,
      },
    });

  } catch (error) {
    console.log(`error al enviar notificacion email`);
  }
}

function Sms(props) {
  const { id } = useParams();
  const [globalState, globalActions] = useGlobal();
  const [phoneParams, setPhoneParams] = useState({
    phone: globalState.dataForm.phone.toString(),
    indicative: '+57',
  });
  const [errorCode, setErrorCode] = React.useState(false);
  const [response, setResponse] = useState(null);
  const [loadingSubscription, setLoadingSusbscription] = useState(
    false
  );
  const [state, setState] = useState({
    loading: false,
    sendingMessage: true,
    code: '',
  });
  const [nextStep, setNextStep] = useState(null);
  const [indicativos, Setindicativos] = useState([]);

  const newPhoneRef = useRef(null);

  let setNewPhone = (x) =>
    setPhoneParams(Object.assign({}, phoneParams, x));

  //mutatuin send Mail Notification
  const [sendEmailInfo] = useMutation(SEND_EMAIL_NOTIFICATION, {
    onError: (error) => console.log('error', error),
    // onCompleted: (data) => {
    //   console.log('se envío el email de forma correcta', data);
    // },
  });

  const [getCardData] = useMutation(GET_CREDIT_CARD, {
    onError: (error) => console.log('erroreessss', error),
    onCompleted: async (data) => {

      const dataEmail = {
        email: globalState.email,
        nameComerce: globalState.commerce.comercio,
        idSuscription: globalState.dataSubscription.suscripcionInfo.id,
        mask: data.getCreditCard.metadata.mask,
        clientId: globalState.commerce.clienteId,
        usuario: globalState.dataSubscription.clienteInfo.usuario
      };

      const dataRequestRefSubscribed = {
        public_key: globalState.commerce.publicKey,
        subscription_id: globalState.dataSubscription.suscripcionInfo.id,
      };


      globalActions.updateState(true)
      // console.log('data correcta', data);
    },
  });

  // const { loading, error, datos } = useQuery(GET_CREDIT_CARD, {
  //   variables: { _id: 'i2EWmNEPqvT4buAui' },
  // });
  //mutatuin send Mail Notification

  // if (loading) {
  //   console.log('loading...');
  //   return null;
  // }
  //
  // if (error) {
  //   console.error(error);
  //   return null;
  // }
  //
  // if (is.null(data['mask'])) {
  //   console.log('card not found');
  //   return null;
  // }
  //Mutation hook
  const [sendSMS] = useMutation(SEND_SMS, {
    onError: (error) => {
      if (process.env.NODE_ENV === 'development')
        console.log('sendSms', error);
    },
    onCompleted: ({ sms }) => {
      const { error} = sms;
      updateState({ sendingMessage: false });
      if (error) {
        // if (process.env.NODE_ENV === 'development')
        // console.error('error sendSMS', error);
      } else {
        if (process.env.NODE_ENV === 'development') {
          console.group('Mensaje de texto enviado');
          // console.log(`SMS:`, payment);
          console.groupEnd();
        }
      }
    },
  });

  //TODO: create action update button admin subscription
  //Mutation hook create token
  // const [createToken] = useMutation(TOKEN_SUBSCRIPTION, {
  //   onCompleted: ({ token }) => {
  //     window.postMessage({ event: 'createToken', token }, '*');
  //   },
  // });

  const [createFacturation] = useMutation(FACTURATION, {
    onError: (error) => {
      setLoadingSusbscription(false);
          globalActions.setErrorAlert({
            icon: 'error',
            title: '¡Error!',
            message: [
              `Ocurrió un error al procesar la solicitud`,
            ],
            content: false,
            hideButtons: false,
            btnText: 'Aceptar',
            btnCallback: () => {
              globalActions.setStep('login');
              globalActions.setDirection(true);
              closeCheckout(
               'error network'
              );

            },
          });

    },
    onCompleted: ({ facturation }) => {
      const { error, payment, responseInfo } = facturation;
      setLoadingSusbscription(true);
      if (error) {
        if (process.env.NODE_ENV === 'development')
          console.error('error createFacturation', error);
      } else {
        if (process.env.NODE_ENV === 'development') {
          console.group('Data facturation step creditCard');
          console.groupEnd();
        }
        globalActions.setResponseInfo(responseInfo);
        if (globalState.isMultipleSubscriptions || responseInfo.card.domiciliacionId) {
          let newCards = globalState.cardsSubscription;
          responseInfo.card.priority = newCards.length + 1;
          newCards.push(responseInfo.card);
        }
        getCardData({ variables: { id: globalState.token, }, });
        const comercio = {
          name: responseInfo.comercio,
          nit: responseInfo.comercioNit
        }
        globalState.isMultipleSubscriptions ? setResponse({ data: payment.data, comercio }) : setResponse({ ...payment.data.data, comercio });
        globalState.isMultipleSubscriptions ? globalActions.setDataResponse({ data: payment.data, comercio }) : globalActions.setDataResponse({ ...payment.data.data, comercio });
        setNextStep(STEP.responseDomiciliation);
      }
    },
  });

  //Mutation hook verification
  const [sendVerification] = useMutation(SMS_VERIFICATION, {
    onError: () => {
      updateState({ code: '' });
      // setValues(['', '', '', '']);
      setLoadingSusbscription(false);
      setErrorCode(true);
      globalActions.setErrorAlert({
        message: [''],
        content: () => (
          <Flex className={'py-4 wc'} direction={'column'}>
            <img
              src="https://multimedia-epayco.s3.amazonaws.com/dashboard/modal_icons/error.png"
              alt=""
              width={'70px'}
              height={'auto'}
              className={'mb-3'}
            />
            <p style={{ fontSize: '18px' }} className={'mb-0'}>
              Ocurrió un error al procesar la verificación del código
            </p>
          </Flex>
        ),
      });
    },
    onCompleted: ({ smsVerification }) => {
      if (!smsVerification.status) {
        setLoadingSusbscription(false);
        updateState({ code: '' });
        globalActions.setErrorAlert({
          message: [''],
          content: () => (
            <Flex className={'py-4 wc'} direction={'column'}>
              <img
                src="https://multimedia-epayco.s3.amazonaws.com/dashboard/modal_icons/error.png"
                alt=""
                width={'70px'}
                height={'auto'}
                className={'mb-3'}
              />
              <p style={{ fontSize: '18px' }} className={'mb-0'}>
                {smsVerification.message}
              </p>
            </Flex>
          ),
        });
      } else {
        if (process.env.NODE_ENV === 'development') {
          console.group('El código ha sido confirmado');
          // console.log(`verification:`, payment);
          console.groupEnd();
        }
        if (
          globalState.type === 'subscription' ||
          globalState.type === 'domiciliation'
        ) {
          let epaycoSubscription = globalState.isMultipleSubscriptions ? globalState.transaction.epaycoMultipleSubscriptions[0]: globalState.transaction.epaycoSubscription;
          let email = epaycoSubscription.email ? epaycoSubscription.email : globalState.isSession.email;
          let nameUser = epaycoSubscription.nameUser ? epaycoSubscription.nameUser : null;
          let subscriptionId = epaycoSubscription.suscripcionId ? epaycoSubscription.suscripcionId : null;
          let accion = epaycoSubscription.accion;


          const form = {
            ...globalState.dataForm,
            email,
            suscriptionProjectId: globalState.suscriptionProjectId,
            parameterSearch: globalState.parameterSearch,
            nameUser,
            subscriptionId,
          };
          if (
            accion &&
            accion ===
              'suscribirPagar'
          ) {
            form.action = 'suscribirPagar';
          } else {
            form.action = 'suscribir';
          }
          createFacturation({
            variables: {
              form,
              type: globalState.type,
              idTransaction: id,
            },
          });
        } else {
          //TODO: action if is payment
        }
      }
      updateState({ code: '' });
      globalActions.updateState(true)
    },
  });

  const Transitions = useTransition(state.sendingMessage, (k) => k, {
    from: {
      opacity: 0,
      transform: state.sendingMessage ? 'scale(1)' : 'scale(0.95)',
    },
    enter: {
      opacity: 1,
      transform: 'scale(1)',
    },
    leave: {
      opacity: 0,
      position: 'absolute',
      height: '100%',
      transform: state.sendingMessage ? 'scale(0.95)' : 'scale(1)',
    },
  });

  useEffect(() => {
    if (indicativos.length === 0) {
      let paises = [];
      axios
        .get('https://secure.payco.co/apprest/paises.json')
        .then((dataresponse) => {
          if (dataresponse.status) {
            paises = dataresponse.data.paises.map((item) =>
              Object.assign(
                {},
                { label: item.nombre, value: '+' + item.indicativo }
              )
            );
            Setindicativos(paises);
          } else {
            console.log('Error al pedir los indicativos ::::::');
          }
        })
        .catch((error) =>
          console.log(
            error,
            'Error al pedir los indicativos ( en el catch )::::::'
          )
        );
    }
  }, []);

  //step change action
  useEffect(() => {
    if (nextStep) {
      // globalActions.setDataForm({
      //   ...globalState.dataForm,
      //   ref_payco: response.ref_payco ? response.ref_payco : '',
      // });
      globalActions.setDataResponse(response);
      // globalActions.setDataSubscription(subscription);

      globalActions.setStep(nextStep);
    }
  }, [globalActions, nextStep]);

  //render action send sms
  useEffect(() => {
    if (is.not.undefined(phoneParams.phone)) {
      sendSMS({
        variables: {
          type: 'send',
          phone: phoneParams.phone,
          indicative: phoneParams.indicative,
          idTransaction: id,
        },
      });
    }
  }, [sendSMS]);


  //otp validation
  let OTP = '';
  // useEffect(() => {
  //
  //   }
  // }, [pin, OTP]);

  //global update state
  const updateState = (data) => {
    setState(Object.assign({}, state, data));
  };

  const SendTonewPhone = (phone) => {
    setNewPhone({
      phone: phone.phone,
      indicative: phone.indicative,
    });

    // setPhoneParams(phone);
    globalActions.setDataForm({
      ...globalState.dataForm,
      ...phone,
    });

    /**
     * Sen new code
     */
    updateState({ sendingMessage: true });
    setErrorCode(false);
    sendSMS({
      variables: {
        type: 'resend',
        idTransaction: id,
        ...phone,
      },
    });

    globalActions.setErrorAlert({
      message: [],
      content: () => (
        <ChangePhone
          paises={indicativos}
          send={SendTonewPhone}
          Reference={newPhoneRef}
        />
      ),
      btns: [
        {
          text: 'Cambiar',
          onClick: () => {
            newPhoneRef.current.dispatchEvent(new Event('submit'));
          },
        },
      ],
    });
  };

  const Sections = useMemo(() => {
    return {
      enviando: () => (
        <Flex
          direction={'column'}
          className="wc p-4 "
          style={{ ...props, minHeight: '250px' }}
        >
          <Loading
            show={true}
            texto={'Enviando mensaje de confirmación...'}
          />
        </Flex>
      ),
      ingresa: () => (
        <Flex
          className={'wc pb-4'}
          flex={'1 0 100%'}
          direction={'column'}
          style={{ ...props, minHeight: '350px' }}
        >
          <Flex className={'py-4'} flex={'0 0 15%'}>
            {/*<img src="/img/security.svg" alt="" width={'80px'} />*/}
            <BigIcon
              icon={'credit_card'}
              color={'#' + globalState.commerce.colorLogo}
            />
          </Flex>
          <div className="wc text-center px-4">
            <b>Ingrese el código de confirmación</b>
            <p>
              Hemos enviado un mensaje al{' '}
              <b>
                {' '}
                {phoneParams.indicative} {phoneParams.phone}
              </b>
              , con el código de confirmación que debe ingresar a
              continuación
            </p>
          </div>
          <div className="wc text-center py-4">
            <InserCode
              errorCode={errorCode}
              send={(obj) => {
                OTP = obj.join('');
                if (OTP.length === 4) {
                  setLoadingSusbscription(true);
                  sendVerification({
                    variables: {
                      code: OTP,
                      idTransaction: id,
                    },
                  });
                }
              }}
            />
          </div>
          <div className="wc text-center py-3">
            <SpanLink
              text={'Reenviar mensaje'}
              icon={'refresh'}
              onClick={(e) => {
                e.preventDefault();
                updateState({ sendingMessage: true });
                setErrorCode(false);
                sendSMS({
                  variables: {
                    type: 'resend',
                    phone: phoneParams.phone,
                    indicative: phoneParams.indicative,
                    idTransaction: id,
                  },
                });
              }}
            />
          </div>
          <div className="wc text-center pb-4">
            <SpanLink
              text={'Cambiar número de teléfono'}
              onClick={(x) => {
                globalActions.setErrorAlert({
                  message: [''],
                  content: () => (
                    <ChangePhone
                      paises={indicativos}
                      send={SendTonewPhone}
                      Reference={newPhoneRef}
                    />
                  ),
                  btns: [
                    {
                      text: 'Cambiar',
                      onClick: () => {
                        newPhoneRef.current.dispatchEvent(
                          new Event('submit')
                        );
                      },
                    },
                  ],
                });
              }}
              icon={'sync_alt'}
            />
          </div>
        </Flex>
      ),
    };
  }, [errorCode, phoneParams]);

  return !loadingSubscription ? (
    <Flex
      flex={'1 0 100%'}
      direction={'column'}
      className={'wc position-relative'}
    >
      {Transitions.map(({ item, props, key }) => {
        let Element = item ? Sections.enviando : Sections.ingresa;
        return (
          <animated.div style={{ ...props, width: '100%' }} key={key}>
            <Element />
          </animated.div>
        );
      })}
    </Flex>
  ) : (
    <Flex
      className={'wc text-center px-4 py-5'}
      direction={'column'}
      flex={'1 0 100px'}
      style={props}
    >
      <Loading
        show={true}
        texto={
          'Estamos procesando la validación de la tarjeta y creando la suscripción.'
        }
      />
    </Flex>
  );
}

Sms.propTypes = {};

Sms.defaultProps = {};

export default React.memo(Sms);
